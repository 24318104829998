import React from 'react'
import './Footer.css'

function Footer() {
  return(
    <div className="Footer">
      <span>{'<> ' + 'Created by Randall Holsinger' + ' </>'}</span>
    </div>
  )
}

export default Footer